<template>
    <div class="app flex-row align-items-center mt-3 p-3" style="background: rgb(248, 249, 250);">
        <div class="container">
            <b-row class="justify-content-center">
                <b-col md="6" sm="8">
                    <b-card no-body class="mx-4 modules-container">
                        <b-card-body class="p-4">
                            <b-form>
                                <h2 style="margin-bottom: 15px;">{{ $t('resetPassword.resetPassword') }}</h2>
                                <p>{{ $t('resetPassword.resetPasswordDesc') }}</p>


                                <b-input-group class="mb-3">
                                    <b-input-group-prepend>
                                        <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input id="password-input" type="password" class="form-control"
                                                  :placeholder="$t('resetPassword.newPassword')"
                                                  v-model.trim="password" autocomplete="new-password"></b-form-input>
                                </b-input-group>

                                <b-input-group class="mb-4">
                                    <b-input-group-prepend>
                                        <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-form-input id="password-confirmation-input" type="password" class="form-control"
                                                  :placeholder="$t('resetPassword.repeatPassword')"
                                                  v-model.trim="passwordConfirmation"
                                                  autocomplete="new-password"></b-form-input>
                                </b-input-group>

                                <p style="color: #f86c6b" v-if="this.hasError">
                                    {{ errorMessage }}
                                </p>

                                <b-button variant="primary" block @click="updatePassword()">{{ $t('resetPassword.updatePassword') }}</b-button>
                            </b-form>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <b-modal id="info-modal" ok-title="Go back to login" centered no-close-on-esc no-close-on-backdrop ok-only
                 header-bg-variant="info" title="Info" @ok="goBack()">

            <div slot="modal-header" class="w-100">
                <h3>Info</h3>
            </div>

            <p class="my-4"><span style="white-space: pre;">$t('resetPassword.resetSuccess')</span></p>
        </b-modal>

    </div>
</template>

<script>
    export default {
        name: 'Register',
        data() {
            return {
                password: "",
                passwordConfirmation: "",
                hasError: false,
                errorMessage: ""
            }
        },

        methods: {
            updatePassword() {
                this.$axios.put('/update-password', {
                    data: {
                        token: this.$route.params.token,
                        password: this.password,
                        passwordConfirmation: this.passwordConfirmation
                    }
                }).then(() => {
                    this.hasError = false;
                    this.errorMessage = false;
                    this.$root.$emit('bv::show::modal', 'info-modal');
                }).catch(err => {
                    this.hasError = true;
                    if (err.response.data.body) {
                        this.errorMessage = err.response.data.body;
                    } else {
                        this.errorMessage = this.$t('resetPassword.errorOccurred');
                    }
                });
            },

            goBack() {
                this.$router.push('/');
            }
        }
    }
</script>






















